@import './variables.less';
@import './mixins.less';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

html,
body {
  text-align: center;
  background-color: @background;
  color: @body;
  font-family: @body-font;
  font-size: 16px;
}

section {
  margin: 2.5rem 0;
}

h1,
h2,
h3 {
  .header-text();
}

h1 {
  font-size: 2.5rem;
  letter-spacing: 1.1rem;
  color: @title;
}

h2 {
  font-size: 1.7rem;
  letter-spacing: 0.6rem;
  font-weight: 700;
  color: @header;
}

h3 {
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  margin-bottom: 0.3rem;
  color: @header;
}

h4 {
  font-weight: bold;
  font-size: 1.1rem;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

a {
  color: @primary;
  text-decoration: none;
  font-size: 1.1rem;

  &:hover,
  &:focus {
    color: @primary-select;
  }
}

p a {
  font-size: 1rem;
}

button {
  width: 100%;
  padding: 0.6rem;
  background-color: @primary;
  border: none;
  border-radius: 1px;
  .header-text();
  color: @background;
  letter-spacing: 0.4rem;
  font-size: 1.2rem;
  margin: 1rem 0 0.5rem;
  transition: background-color 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background-color: @primary-select;
  }
}
