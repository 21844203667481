@import './variables.less';
@import './keyframes.less';

.header-text() {
  margin: 1rem 0;
  font-family: @header-font;
  line-height: 1.4em;
  text-transform: uppercase;
}

.outline-style() {
  outline-color: @primary;
  outline-width: 2px;
}

.legibility-shadow() {
  text-shadow: 1px 1px 1px white;
}

.transform-animation(@name, @duration: 1s) {
  animation: @name @duration;
}
